<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
      <v-card-title> Cambiar Contraseña </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <v-col>
              <v-alert color="warning" text>
                <p class="font-weight-semibold mb-1">Asegúrese de que se cumplan estos requisitos</p>
                <p class="text-sm mb-0">Mínimo 8 caracteres de largo, mayúsculas, números, y carácteres especiales</p>
              </v-alert>
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                outlined
                dense
                label="Contraseña actual"
                :error-messages="errorMessages.currentPassword"
                :rules="[validators.required]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col>
              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="Nueva contraseña"
                :error-messages="errorMessages.newPassword"
                :rules="[validators.required, validators.passwordValidator]"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col>
              <!-- confirm password -->
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                label="Confirmación nueva contraseña"
                :error-messages="errorMessages.confirmPassword"
                :rules="[validators.required, validators.passwordValidator]"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-col>

          <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
            <v-img
              contain
              max-width="170"
              src="@/assets/images/3d-characters/pose-m-1.png"
              class="security-character"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- action buttons -->
      <v-card-text>
        <v-btn color="primary" class="me-3 mt-3" type="submit"> Guardar </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { required, passwordValidator } from '@core/utils/validation'
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from 'vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const userDataStorage = store.getters['user/getUser']
    const errorMessages = ref([])
    const valid = ref(false)
    const form = ref(null)

    // on form submit
    const onSubmit = () => {
      errorMessages.value = []
      if (valid.value) {
        if (newPassword.value == confirmPassword.value) {
          store
            .dispatch('user/changePassword', {
              _id: userDataStorage._id,
              currentPassword: currentPassword.value,
              newPassword: newPassword.value,
            })
            .then(r => {
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
              form.value.reset()
            })
            .catch(e => {
              errorMessages.value = e.response.data.message
            })
        } else {
          errorMessages.value = { confirmPassword: ['Revise que las contraseñas sean iguales.'] }
        }
      } else {
        form.value.validate()
      }
    }

    return {
      onSubmit,
      errorMessages,
      valid,
      form,
      isPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      confirmPassword,
      validators: {
        required,
        passwordValidator,
      },
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
