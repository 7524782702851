<template>
  <div>
    <v-alert type="warning" dismissible v-show="missingData.length">
      Por favor, completa los siguientes datos de tu perfil: {{ missingData.join(', ') }}
    </v-alert>
    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <profile-info v-if="Object.keys(profileData).length" :profile-data="profileData"></profile-info>
        </v-tab-item>

        <v-tab-item>
          <profile-security></profile-security>
        </v-tab-item>

        <v-tab-item>
          <profile-notification :profile-data="profileData"></profile-notification>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
} from '@mdi/js'
import { ref, onUnmounted } from 'vue'
import store from '@/store'

// demos
import ProfileInfo from './ProfileInfo.vue'
import ProfileSecurity from './ProfileSecurity.vue'
import ProfileNotification from './ProfileNotification.vue'

export default {
  components: {
    ProfileInfo,
    ProfileSecurity,
    ProfileNotification,
  },
  setup() {

    const tab = ref('')
    const profileData = ref({})
    const missingData = ref([])

    // tabs
    const tabs = [
      { title: 'Perfil', icon: mdiAccountOutline },
      { title: 'Seguridad', icon: mdiLockOpenOutline },
      { title: 'Notificaciones', icon: mdiBellOutline },
    ]

    const loadProfile = () => {
      store
        .dispatch('user/fetchProfile')
        .then(response => {
          profileData.value = response.data.user
          missingDataAlert()
        })
        .catch(error => {
          if (error.response.status === 404) {
            profileData.value = {}
          }
        })
    }

    loadProfile()

    const missingDataAlert = () => {
      missingData.value = []
      if (!profileData.value.phone) missingData.value.push('Teléfono')
      if (!profileData.value.email) missingData.value.push('Email')
      if (!profileData.value.address) missingData.value.push('Dirección')
      if (!profileData.value.city) missingData.value.push('Ciudad')
      if (!profileData.value.birthday) missingData.value.push('Fecha de Nacimiento')
    }

    return {
      missingData,
      tab,
      tabs,
      profileData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
